"use strict";

var _interopRequireDefault = require("/Users/tanglewang/htdocs/hsgx/anxinshouhu_h5/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("/Users/tanglewang/htdocs/hsgx/anxinshouhu_h5/node_modules/@babel/runtime-corejs2/helpers/defineProperty"));

var _area = _interopRequireDefault(require("./area.json"));

//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: ['value', 'areaCode'],
  watch: {
    value: function value(newVal) {
      this.result = this.value;
    },
    show: {
      handler: function handler(newVal) {
        var _this = this;

        console.log(newVal);

        if (newVal === true) {
          this.$nextTick(function () {
            _this.$set(_this, 'comAreaCode', _this.areaCode); // this.comAreaCode = this.areaCode


            console.log('this.comAreaCode = this.areaCode = ' + _this.comAreaCode);
          });
        }
      },
      deep: true,
      immediate: true
    }
  },
  data: function data() {
    return {
      result: this.value,
      show: false,
      areaList: _area.default,
      comAreaCode: this.areaCode
    };
  },
  methods: {
    confirm: function confirm(e) {
      this.show = !this.show;
      this.$emit('confirm', (0, _defineProperty2.default)({}, this.$attrs.field, e[2]['code']));
    }
  }
};
exports.default = _default;