var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "van-field",
        _vm._b(
          {
            attrs: {
              readonly: "",
              "is-link": "",
              "input-align": _vm.inputAlign
            },
            on: {
              click: function($event) {
                _vm.show = !_vm.show
              }
            },
            model: {
              value: _vm.result,
              callback: function($$v) {
                _vm.result = $$v
              },
              expression: "result"
            }
          },
          "van-field",
          _vm.$attrs,
          false
        )
      ),
      _vm._v(" "),
      _c(
        "van-popup",
        {
          attrs: { position: "bottom" },
          model: {
            value: _vm.show,
            callback: function($$v) {
              _vm.show = $$v
            },
            expression: "show"
          }
        },
        [
          _c("van-picker", {
            attrs: {
              columns: _vm.columns,
              "show-toolbar": "",
              title: _vm.$attrs.label
            },
            on: {
              cancel: function($event) {
                _vm.show = !_vm.show
              },
              confirm: _vm.onConfirm
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }