"use strict";

var _interopRequireDefault = require("/Users/tanglewang/htdocs/hsgx/anxinshouhu_h5/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

require("core-js/modules/es6.number.constructor");

var _imageCompress = _interopRequireDefault(require("@/mixins/image-compress"));

var _index = require("@/api/helper/index");

//
//
//
//
//
//
//
//
//
var _default2 = {
  mixins: [_imageCompress.default],
  props: {
    type: {
      type: Array,
      default: function _default() {
        return ['image/jpeg', 'image/png'];
      }
    },
    url: {
      type: String,
      default: function _default() {}
    },
    maxsize: {
      type: Number,
      default: function _default() {
        return 5;
      }
    }
  },
  data: function data() {
    return {};
  },
  methods: {
    /**
     * 上传图片之前判断图片是否符合条件
     */
    beforeRead: function beforeRead(file) {
      var _this = this;

      this.$toast.loading({
        mask: false,
        message: '上传中...'
      });
      console.log('before read......');

      if (!this.type.includes(file.type)) {
        this.$toast('请上传 jpg/png 格式图片');
        return false;
      }

      this.checkAndHandleCompression(file).then(function (fileOptions) {
        var formData = new FormData();
        formData.append('file', fileOptions.uploadFile);
        (0, _index.upload)(formData).then(function (res) {
          _this.$toast.clear();

          _this.$emit('uploadComplete', res);
        });
      });
      return true;
    },
    afterRead: function afterRead(file) {}
  },
  created: function created() {}
};
exports.default = _default2;