var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "van-overlay",
        {
          attrs: {
            show: _vm.isShow,
            "custom-style": {
              display: "flex",
              justifyContent: "center",
              paddingTop: "80%"
            }
          },
          on: { click: _vm.hide }
        },
        [
          _c("van-loading", { attrs: { size: "30px", color: "#4994df" } }, [
            _c("span", { staticStyle: { color: "#4994df" } }, [
              _vm._v(_vm._s(_vm.title || "加载中···"))
            ])
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }