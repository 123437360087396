var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "van-button",
    {
      staticClass: "verify-btn",
      attrs: { size: "mini", type: "primary", disabled: _vm.disabled },
      on: { click: _vm.btnClick }
    },
    [
      _vm._v(
        "\n  " +
          _vm._s(_vm.codeRestTime ? _vm.codeRestTime + "秒" : this.btnMsg) +
          "\n"
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }